import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";

import Projects from "../components/Projects";
export const WorkPageTemplate = ({ projects }) => {
  return (
    <section className="section ">
      <div className="container">
        <Projects projects={projects} />
      </div>
    </section>
  );
};

// WorkPageTemplate.propTypes = {
//   title: PropTypes.string.isRequired,
//   content: PropTypes.string,
//   contentComponent: PropTypes.func
// };

const WorkPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout location={location}>
      <WorkPageTemplate projects={frontmatter.projects} />
    </Layout>
  );
};

WorkPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default WorkPage;

export const WorkPageQuery = graphql`
  query WorkPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        projects {
          name
          type
          role
          description
          url
          file
          image
        }
      }
    }
  }
`;
