import React, { Component } from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";
import { Waypoint } from "react-waypoint";

function fileValidation(file) {
  const regex = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
  const isImage = file.match(regex);
  if (isImage) {
    return true;
  }
  return false;
}

const GridHeader = () => {
  return (
    <div className="grid-table-row grid-table-row--header">
      <div className="grid-table-cell">Project</div>
      <div className="grid-table-cell">Type</div>
      <div className="grid-table-cell">Role</div>
      <div className="grid-table-cell" />
    </div>
  );
};

const Projects = ({ projects }) => (
  <div className="grid-table">
    {projects.map((item, i) => (
      <div key={i}>
        <GridHeader />
        <div
          key={v4()}
          className={`grid-table-row ${
            item.url ? "grid-table-row--3-col" : ""
          }`}
        >
          <div className="grid-table-cell" data-title="Project">
            {item.name}
          </div>
          <div className="grid-table-cell" data-title="Type">
            {item.type}
          </div>
          <div className="grid-table-cell" data-title="Role">
            {item.role}
          </div>
          <div className="grid-table-cell">
            {item.url && (
              <a className="link" href={item.url}>
                View
              </a>
            )}
          </div>
        </div>
        <div className="grid-table__view">
          {item.file !== "" ? (
            <div className="grid-table__thumb">
              <div className="grid-table__top-bar">
                <div className="grid-table__circles">
                  <div className="grid-table__circle" />
                  <div className="grid-table__circle" />
                  <div className="grid-table__circle" />
                </div>
              </div>
              {fileValidation(item.file) ? (
                <>
                  <img
                    className="grid-table__desktop is-hidden-mobile"
                    src={item.file}
                  />
                  <img
                    className="grid-table__mobile is-hidden-tablet"
                    src={item.image}
                  />
                </>
              ) : (
                <Waypoint
                  onEnter={() => {
                    const video = document.getElementById(`video-${i}`);
                    video.play();
                  }}
                  onLeave={() => {
                    const video = document.getElementById(`video-${i}`);
                    video.pause();
                  }}
                >
                  <div>
                    <img
                      className="grid-table__mobile is-hidden-tablet"
                      src={item.image}
                    />
                    <video
                      id={`video-${i}`}
                      className="grid-table__desktop is-hidden-mobile"
                      src={item.file}
                      loop
                    />
                  </div>
                </Waypoint>
              )}
            </div>
          ) : (
            <div className="grid-table__thumb">
              <div className="grid-table__top-bar">
                <div className="grid-table__circles">
                  <div className="grid-table__circle" />
                  <div className="grid-table__circle" />
                  <div className="grid-table__circle" />
                </div>
              </div>
              <div className="placeholder" />
            </div>
          )}

          <div className="grid-table__description">{item.description}</div>
        </div>
      </div>
    ))}
  </div>
);

Projects.propTypes = {
  Projects: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string
    })
  )
};

export default Projects;
